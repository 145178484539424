.webstoreRadioGroup {
    text-align: center;
    width: 100%;
  }
  .serviceIcon svg {
    vertical-align: -webkit-baseline-middle;
  }
  .webstoreRadioGroup .ant-radio-button-wrapper {
    height: 45px;
    /* box-shadow: 0 0 7px 0 rgba(170, 170, 170, 0.5);
    background-color: #ffffff; */
  }
  .webstoreRadioGroup .ant-radio-button-wrapper-checked {
    box-shadow: 0 0 7px 0 rgba(170, 170, 170, 0.5);
    /* border: solid 2px #3666c4; */
    background-color: #ffffff;
  }
.ant-form-item label {
    font-size: 9px;
    display: inline-block;
  }