.uploadBtn {
    margin-top: 16;
    color: #fff;
    background-color: #5c6575;
    border-color: #5c6575;
  }
  
  .uploadBtn:hover,
  .uploadBtn:active,
  .uploadBtn:focus {
    color: #5c6575;
    background-color: white;
    border-color: #5c6575;
  }
  /***** overide css classess */
  