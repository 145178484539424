body,
html {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin: 0;
  font-size: 15px;
}
.loginFormButton{
  position: relative;
  left: 35%;
  background: #238661;
  color: white;
  border-color: #238661;
  /* font-size: 15px; */
}
.mainDiv {
  background-image: url("../../assets/img/Grower-Console-login.png");
  height: 100%;
  margin: 0;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
}
.pkMainDiv {
  background-image: url("../../assets/img/pk-login.png");
  height: 100vh;
  width: 100%;
  margin: 0;
  /* Center and scale the image nicely */
  background-position: initial;
  background-repeat: round;
  background-size: cover; 
}
.Loginbtn {
  background: #238661;
  color: white;
  border-color: #238661;
  font-size: 15px;
  width: 100%;
}
.loginButtonBackground {
  padding: 5% 5%;
  border-radius: 5px;
  background: #ffffff
}
.pkLoginButtonBackground{}
.pkLoginbtn {
  background: #b9c519;
  color: white;
  border-color: #b9c519;
  font-size: 1.5625vw;
  width: 100%;
  position: absolute;
  top: 168px;
  left:0px;
  height: 35px;
}
.pkLoginbtn:hover {
  background: #b9c519;
  color: white;
  border-color: #b9c519;
}
.Loginbtn:hover {
  background: #238661;
  color: white;
  border-color: #238661;
}
.latamLoginbtn {
  background: #238661;
  color: white;
  border-color: #238661;
  font-size: 1.000em;
  width: 100%;
}
.latamLoginbtn:hover {
  background: #238661;
  color: white;
  border-color: #238661;
}
.thLoginbtn {
  background: #238661;
  color: white;
  border-color: #238661;
  font-size: 15px;
  width: 100%;
}
.thLoginbtn:hover {
  background: #238661;
  color: white;
  border-color: #238661;
}
.myLoginbtn {
  background: #238661;
  color: white;
  border-color: #238661;
  font-size: 15px;
  width: 100%;
}
.phLoginbtn
  {
    background: #238661;
    color: white;
    border-color: #238661;
    font-size: 15px;
    width: 100%;
  }
  .phLoginbtn:hover {
    background: #238661;
    color: white;
    border-color: #238661;
  }
.myLoginbtn:hover {
  background: #238661;
  color: white;
  border-color: #238661;
}
.idnLoginbtn {
  background: #238661;
  color: white;
  border-color: #238661;
  font-size: 15px;
  width: 100%;
}
.idnLoginbtn:hover {
  background: #238661;
  color: white;
  border-color: #238661;
}
.LoginCard {
  width: 31%;
  margin: 9% auto;
  background: transparent;
}
.gridStyle {
  width: 100%;
  text-align: center;
}
.Loginfooter {
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 1em;
  background: #f0f2f5;
  position: fixed;
  bottom: 0;
  width: 100%;
}

/*** Override css classes**/

.ant-card-grid {
  box-shadow: none;
}

/******* Media Queries **/
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 400px) and (max-width: 799px) {
}
/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 800px) and (max-width: 999px) {
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1000px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1259px) {
}
@media (min-width: 1260px) and (max-width: 1399px) {
}
@media (min-width: 1400px) and (max-width: 1450px) {
  .Loginbtn {
    background: #238661;
    color: white;
    border-color: #238661;
    font-size: 12px;
    width: 100%;
  }
}
/* Media Query for Large screens */
@media (min-width: 1451px) and (max-width: 1550px) {
  .Loginbtn {
    background: #238661;
    color: white;
    border-color: #238661;
    font-size: 12px;
    width: 100%;
  }
}
