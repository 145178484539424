.mainDrawer {
  /* background-color: #238661; */
  height: 100vh;
  /* background: #238661; */
  /* color: white; */
  left: 0;
  overflow: hidden;
  z-index: 99;
}
.Drawermenu li:hover span > a {
  color: #f6ca46;
}
.ant-menu-item:hover i > svg > g > g {
  fill: #f6ca46;
}

.ant-menu-item:hover i > svg > g {
  fill: #f6ca46;
}

.Drawermenu .ant-menu-item:hover i > svg > path {
  fill: #f6ca46;
}

.Drawermenu {
  background-color: transparent;
  color: #ffffff;
}

.DrawerSubmenu {
  border-bottom: 1px solid #15563e8c;
}
.DrawerSubmenu:hover {
  color: #f6ca46;
  cursor: pointer;
}
.DrawerSubmenu:hover span {
  color: #f6ca46;
}
.DrawerSubmenu .ant-menu-sub.ant-menu-inline > .ant-menu-item:hover a {
  color: #f6ca46;
}
.Drawermenu li:hover .ant-menu-submenu-title > a > li > i > svg > g > g {
  fill: #f6ca46;
}
.Drawermenu li:hover .ant-menu-submenu-title > a > li > i > svg > g {
  fill: #f6ca46;
}
.Drawermenu li:hover .ant-menu-submenu-title > a > li > i > svg > path {
  fill: #f6ca46;
}
.ant-menu-submenu-selected .ant-menu-submenu-title > a > li > i > svg > path {
  fill: #f6ca46;
}
.growerIcon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}
.growerLabel {
  vertical-align: middle;
  color: white;
}
.growerMenuitem {
  border-bottom: 1px solid #15563e8c;
  cursor: pointer;
}
.growerMenuitem:hover a > span {
  color: #f6ca46;
}

/*****Overrided classes***/

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  background: transparent;
}

.ant-menu-item-selected > a > span {
  color: #f6ca46;
  cursor: pointer;
}

.ant-menu-inline .ant-menu-item {
  padding: 7px 18px !important;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 7px 18px !important; /* Note:- already added important in the framwork antd class since we have to add here important */
}
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0px 18px !important; /* Note:- already added important in the framwork antd class since we have to add here important */
}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 18px !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: 0px;
}

.ant-menu-item > a > span {
  color: white;
}

.ant-menu-item > a > span :hover {
  color: #f6ca46;
}
.ant-menu-item-selected > a > span {
  color: #f6ca46;
}
.ant-menu-item-selected > a > span {
  color: #f6ca46;
}
.ant-menu-inline .ant-menu-item-selected::after {
  color: #f6ca46;
  border-right: 3px solid #f6ca46;
}

.Drawermenu .ant-menu-item-selected a > .anticon > svg > g > g {
  fill: #f6ca46;
  color: #f6ca46;
}
.ant-menu-item-selected a > .anticon > svg > g {
  fill: #f6ca46;
  color: #f6ca46;
}
.Drawermenu .ant-menu-item-selected a > .anticon > svg > path {
  fill: #f6ca46;
  color: #f6ca46;
}

.ant-menu-submenu-selected {
  color: #f6ca46;
}
.ant-menu-submenu-selected .ant-menu-submenu-title > a > li > .growerLabel {
  color: #f6ca46;
}
.ant-menu-submenu-selected
  > .ant-menu-submenu-title
  li
  > .anticon
  > svg
  > g
  > g {
  color: #f6ca46;
  fill: #f6ca46;
}
.ant-menu-item:hover a {
  color: #f6ca46;
}
.growerMenuitem > a {
  color: #ffffff;
  display: inline-flex;
}
.ant-menu-item > a {
  color: #ffffff;
}
.ant-menu-submenu .ant-menu-item-selected > a {
  color: #f6ca46;
}

.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #f6ca46, #f6ca46);
}

.ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  background: #f6ca46;
}
.ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  background: #f6ca46;
}

.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  background-image: unset;
}
.ant-progress-inner {
  background-color: #f5f5f508;
}

.Drawermenu > .ant-menu-item {
  height: 55px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  margin-top: 0;
}
/******* Media Queries **/
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1425px) {
  /* big landscape tablets, laptops, and desktops */
  .siderFooter {
    top: 82%;
  }
  .footerLogo {
    width: 50.9%;
  }
}
@media (min-width: 1881px) {
  /* hi-res laptops and desktops */
}
