.chat {
    flex: 0.72 1;    
    display: flex;
    flex-direction: column;
    height: calc(100% - 0px);
}
.chat-room-name {
    margin: 0px;
}
.chat-room-last-seen {
    margin: 0;
}
.chat_header {
    display: flex;
    padding: 11px;
    align-items: center;
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
}
.chat_headerInfo {
    flex: 1;
    padding-left: 20px;
}

.chat_headerInfo > h3 {
    margin-bottom: 3px;
    font-weight: 500;
}

.chat_headerInfo > p {
    color:gray;
}

.chat_headerRight {
    display: flex;
    justify-content: space-between;
    min-width: 100px;
}

.chat_body {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    /* background-color: #ffffff; */
    /* background-color: peachpuff; */
    background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
    background-repeat: repeat;
    /* background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTawkeTJaQxn-WLgCr52UJwlJ5W8czbAWr7w&usqp=CAU'); */
    background-position: center;
    background-position: 50%;
    padding: 30px;
    overflow:auto; 
}
.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper {
    display: block;
}
/* .ant-form-item {
    margin-bottom: 0px;
} */
.message{
    display: flex;
    flex-direction: column;
}
.message>.message-container{
    font-size: 14px;
    display: flex;
}
.bubbleMessageSent{
    display: flex;
    flex-direction: column;
}
.bubbleMessageSent>.bubble-container{
    font-size: 14px;
    display: flex;
}
.bubbleMessageSent>.bubble-container>.bubble{
    background: #007aff;
    color: #fff;
    max-width: 75%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.chat_message {
    position: relative;
    font-size: 16px;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    /* background-color: #2d3e50; */
    margin-bottom: 5px;
    max-width: 65%;
    width: fit-content;
    word-break: break-all;
}
.chatInputBox{
    height: fit-content;
}
.caption{
    position: relative;
    top:5px;
    color: #000000; 
}
.chat_messageSent {
    position: relative;
    font-size: 16px;
    /* float: right; */
    margin-left: auto;
    background-color: #dcf8c6;
     /* background-color: #238661; */
    border-radius: 10px;
    padding: 10px;
    /* background-color: white; */
    width: fit-content;
    max-width: 65%;
    word-break: break-all;
    margin-bottom: 5px;
}
.chat_name {
    position: absolute;
    top:-15px;
    font-weight: 800;
    font-size: xx-small;
    color: #000000;
}
.chat_timestamp_Media {
    /* float: right; */
    display: flex;
    flex-direction: row-reverse;
    margin: -7px 4px -9px 4px;
    height: 15px;
    white-space: nowrap;
    font-size: 11px;
    color: #ffffff;
    position: relative;
    top: -12px;
    /* margin-left: 10px;
    font-size: xx-small;
    color: #000000; */
    /* color: #ffffff; */
}
.chat_timestamp {
    /* float: right; */
    display: flex;
    flex-direction: row-reverse;
    margin: -7px 0 -9px 4px;
    height: 15px;
    white-space: nowrap;
    font-size: 11px;
    color: #00000073;
    /* margin-left: 10px;
    font-size: xx-small;
    color: #000000; */
    /* color: #ffffff; */
}
.chat_timestamp_Sent {
    display: flex;
    flex-direction: row-reverse;
    margin: -7px -5px -9px 4px;
    height: 15px;
    white-space: nowrap;
    font-size: 11px;
    color: #00000073;
}
.messageFailed{
    display: flex;
    flex-direction: column;
    color: red;
}
.chat_messageText {
    white-space: break-spaces;
    word-wrap: break-word;
    color:#000000;
    /* color: #ffffff; */
}
.chat_receiver{
    margin-left: auto;
    background-color: #dcf8c6;
}

.chat_footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    border: 1px solid lightgray;
}

.chat_footer > form{
    flex: 1;
    display: flex;
}

.chat_footer > form > input{
    flex: 1;
    border-radius: 30px;
    padding: 10px;
    border: none;
    margin: 0 10px 0px 10px;
}

.chat_footer > form > button{
    /* display: none; */
}
.chat_footer> form > .ant-form-item{
    margin-bottom: 1px !important;
}
.emoji-mart-preview{
    display: none;
}