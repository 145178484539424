.mainaddGrower {
    margin: 5px 5px;
  }
  .addgrowerList {
    border-radius: 5px;
    text-align: center;
  }
  .ant-drawer-header{
    text-align: center;
  }
  .addGrowerCard {
    font-size: 15px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #3666c4;
    border-radius: 10px;
    cursor: pointer;
    color: #3666c4;
  }
  .addGrowerCard .ant-card-body {
    padding: 8px 24px;
  }
  .importGrowerCard {
    font-size: 15px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #3666c4;
    border-radius: 10px;
    cursor: pointer;
    color: #3666c4;
  }
  .importGrowerCard .ant-card-body {
    padding: 7px 24px;
  }
  .importGrowerCard .ant-card-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .importGrowerCard .ant-card-body ul li {
    float: left;
    display: inline-block;
    margin-right: 11%;
  }
  .importGrowerCard .ant-card-body ul li img {
    height: 25px;
  }
  .importIcondiv {
    display: inline-flex;
    margin: 8px 0%;
    height: 30px;
  }
  .uploaCard .ant-card-body {
    padding: 10px 0px;
  }
  .schemaSelect {
    width: 235px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }
  
  .notificationbox {
    display: flex;
    position: absolute;
    padding: 0.5% 1%;
    top: 0;
    left: 15.6%;
    width: 85%;
    height: 8%;
    background: #e1f7cf;
    color: #72b040;
    z-index: 1015;
  }
  
  .notificationErrorbox {
    display: flex;
    position: absolute;
    padding: 0.5% 1%;
    top: 0;
    left: 15.6%;
    width: 85%;
    height: 8%;
    background: #e1f7cf;
    color: #e65252;
  }
  
  .notyfyCloseIcon {
    float: right;
  }
  .notyfyCloseIconDiv {
    width: 2%;
    padding: 1%;
  }
  
  /**********Override css classes*********/
  
  .ant-card-body::before,
  .ant-card-body::after {
    display: unset;
  }
  .ant-drawer-body {
    padding: 10px;
  }
 
  
  .ant-notification .ant-notification-topRight {
    top: 24px;
    bottom: auto;
    left: 20%;
    right: 129px;
    width: 90%;
    background-color: #e1f7cf;
  }
  .ant-list-grid .ant-col > .ant-list-item {
    margin-bottom: 0;
  }
  .ant-drawer-wrapper-body {
    overflow: hidden;
  }
  
  /******* Media Queries **/
  /* Media Query for low resolution  Tablets, Ipads */
  @media (min-width: 400px) and (max-width: 799px) {
  }
  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 800px) and (max-width: 999px) {
  }
  
  /* Media Query for Laptops and Desktops */
  @media (min-width: 1000px) and (max-width: 1199px) {
    .addGrowerCard {
      font-size: 13px;
    }
    .importGrowerCard .ant-card-body {
      padding: 7px 24px;
    }
    .addGrowerCard {
      font-size: 11px;
    }
    .importGrowerCard {
      font-size: 11px;
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1259px) {
    .addGrowerCard {
      font-size: 13px;
    }
    .importGrowerCard .ant-card-body {
      padding: 8px 20px;
    }
    .importGrowerCard {
      font-size: 13px;
    }
  }
  @media (min-width: 1260px) and (max-width: 1399px) {
    .addGrowerCard {
      font-size: 17px;
    }
    .importGrowerCard .ant-card-body {
      padding: 8px 20px;
    }
    .importGrowerCard {
      font-size: 17px;
    }
  }
  @media (min-width: 1400px) and (max-width: 1450px) {
    .mainaddGrower {
      margin: 2% 2% 0;
      width: 82.5%;
    }
    .notificationbox {
      left: 14%;
      width: 86%;
      height: 7%;
    }
  }
  /* Media Query for Large screens */
  @media (min-width: 1451px) and (max-width: 1550px) {
  }
  
  /* Media Query for Large screens */
  @media (min-width: 2000px) and (max-width: 2550px) {
    .notificationbox {
      left: 10.4%;
      width: 90%;
    }
  }
  