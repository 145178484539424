.whatsapp {
    display: grid;
    place-items: center;
    background-color: #dadbd3;
    height: 100vh;
}

.whatsapp_body {
    display: flex;
    background-color: #ededed;
    margin-top: -35px;
    width: 90vw;
    height: 90vh;
    min-width: 900px;
    box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.5);
}