.ant-form-item {
    margin-bottom: 10px !important;
}
.growerFormLayout {
    background-color: #EBEBEB;
    height: 100vh;
    width: 100%;
    margin: 0;
    /* Center and scale the image nicely */
    background-position: initial;
    background-repeat: round;
    background-size: cover;
    padding: '0px';
    /* overflow: auto; */
}
.ant-result {
background-color: #fff;
}
.submitBtn{
    margin-top: 12px;
}
.avatarImageMY{
    /* width: 100px; */
    height: 5vh;
    position: relative;
    /* float: left; */
    top: -20px;
    mix-blend-mode: multiply;
}
.avatarImageTH{
    height: 5vh;
    position: relative;
    top: -23px;
    mix-blend-mode: multiply;
}
.avatarImage{
    /* width: 100px; */
    height: 5vh;
    position: absolute;
    /* float: left; */
    top: 21px;
    mix-blend-mode: multiply;
}
.buttonStyle{
    border-radius: 8px;
    color: #fff;
    background-color: #238661;
     width: 47%;
    /* width:300%; */
    top: 15px;
     float: right; 
}
.addGrowerFrom .ant-select-selection--single {
    /* display: flex; */
    display: grid;
    background-color: #F3F4F6;
} 
.backgroundImage{
    background-image: url("../assets/img/th-login.png");
    height: 100vh;
    width: 100%;
    margin: 0;
    /* Center and scale the image nicely */
    background-position: initial;
    background-repeat: round;
    background-size: cover;
}
.ClearButtonStyle{
    border-radius: 8px;
    color: #000;
    background-color: #EBEBEB;
    width: 47%;
    top: 15px;
}
.loginFormLabel.ant-form label { 
    font-size: 14px;
   } 
/* .ant-form label {
 font-size: 14px;
} */
.loginFormLabel{
    font-size: 14px;
    margin-bottom: 0px;
    display: inline;
}
.ant-form-vertical .ant-form-item {
    padding-bottom: 0px;
}
.ant-input{
    background-color: #F3F4F6;
}
.addGrowerFrom.ant-select-selection{
    background-color: #F3F4F6;
}
.ant-collapse-header{
    font-weight: 600;
}
.Title{
    margin: 0 auto;
    padding-bottom: 10px;
    text-align: center;
}
#FirstInput::-webkit-inner-spin-button, 
    #FirstInput::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
/******* Media Queries **/
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 400px) and (max-width: 799px) {
    .addGrower{
        width: 100%;
    }
    
}
@media only screen and (min-width: 411px) and (max-width: 767px) {
    .addGrower{
    width: 100%;
} }

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 800px) and (max-width: 999px) {
    .addGrower{
        width: 100%;
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1000px) and (max-width: 1199px) {
    .addGrower{
        width: 100%;
    }
}

@media (min-width: 1200px) and (max-width: 1259px) {
    .addGrower{
        width: 40%;
    }
}
@media (min-width: 1260px) and (max-width: 1399px) {
    .addGrower{
        width: 40%;
    }
}
@media (min-width: 1400px) and (max-width: 1450px) {
    .addGrower{
        width: 40%;
    }
}
/* Media Query for Large screens */
@media (min-width: 1451px) and (max-width: 1550px) {
    .addGrower{
        width: 40%;
    }
}
