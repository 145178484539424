.highLight {
    background-color: #eeffdc;
  }
  .FilterLetter{
    text-transform: capitalize;
  }
  .MemberSearch{
    position: absolute;
    top: 2%;
    right: 5%;
    width: 100%;
  }
  .scroll{
    overflow-y: auto;
    height: 100%;
  }