.sidebar {
    flex: 0.28 1;
    display: flex;
    flex-direction: column;
    /* background-color: #2d3e50; */
    /* background-color: #ffffff; */
}

.sidebar_header {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #f6f6f6;
}
.connection{
    background-color:#fed859;
    /* padding: 20px; */
    display: flex;
    /* justify-content: center; */
    padding: 12px;
    border-bottom: 1px solid #f6f6f6;
}
.connection_Right{
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    /* min-width: 10vw; */
    padding-left: 15px;
    color: #000000;
}
.connection_Heading{
    font-size: 16px;
    margin-bottom: 5px;
}
.wifi-notConnect{
    height: 41px;
    width: 30px;
}
.connection_subHeading{
    font-size: 14px;
}
.connection_Right>p{
    word-break: break-all;
}
.sidebar_headerRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 10vw;
}

.sidebar_headerRight>.ant-btn {
    margin-right: 2vw !important;
    font-size: 20px !important;
}

.sidebar_search {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    height: 39px;
    padding: 10px;
}

.sidebar_searchContainer {
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 35px;
    border-radius: 20px;
}

.sidebar_searchContainer>input {
    border: none;
    width: 100%;
}

.sidebar_searchContainer>.ant-btn {
    color: gray;
    padding: 10px
}

.sidebar_chats {
    flex: 1;
    /* overflow: scroll; */
     background-color: white; 
    /* background-color: #  ; */
    
}
.header{
    /* position: relative; */
    /* right: 120px; */
    margin-left: 20px;
    color: #000000;
}
.sidebar_chats::-webkit-scrollbar {
    display: none;
}