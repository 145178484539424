.thirdDiv {
  width: 83%;
  text-align: center;
  margin: 0 auto;
padding-top: 40px;
  /* text-align: center; */
}
#coordinated_SocialPlatform .ant-radio-button-wrapper {
  padding: 6px 6px;
  height: 50px;
  width: 65px;
  margin: 0 11px;
}
.ant-calendar-time-picker-select-option-disabled{
  pointer-events: none;
}
#coordinated_SocialPlatform {
  width: 100%;
  text-align: center;
}
.costMsgText{
  position: absolute;
  color: red;
  top: 350px;
  left: 35px;
  font-size: 13px;
}
.WhatsAppText{
  position: absolute;
  color: red;
  top: 400px;
  left: 35px;
  font-size: 13px;
}
.costErrorMsgText{
  color:red;
}
#coordinated_messageType {
  width: 100%;
  text-align: center;
}
.NewMessgeCard .ant-card-body {
  padding: 0px;
}
.messagegroupType {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 95%;
}
.StepsprevBtn {
  color: #238661;
  margin-left: 8px;
  float: left;
  text-transform: uppercase;
}
.StepsprevBtn:hover {
  color: #238661;
}
#coordinated_messageType .ant-radio-button-wrapper {
  margin: 20px;
}
.firstStepTextualIcon {
  line-height: 5.7;
  height: 30px;
}
/********* Override classess ******/

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #238661;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #238661;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #238661;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #238661;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #238661;
}
.ant-calendar-picker-input .ant-input{
  color: #238661;
}
.scheduleLater{
  float: right;
    color: #fff;
    background-color: #238661;
    border-color: #238661;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.scheduleLater> span {
margin-left: -17px;
}
.steps-action > .ant-btn:hover,
.steps-action > .ant-btn:focus {
  /* color: #238661; */
  /* background-color: #fff; */
  border-color: #238661;
}
.steps-action > .ant-btn-primary,
.steps-action > .ant-btn-primary:hover {
  color: #fff;
  background-color: #238661;
  border-color: #238661;
}

.ant-radio-button-wrapper {
  border: 0;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}
.ant-radio-button-wrapper:first-child {
  border: 0;
}
.ant-radio-button-wrapper-checked {
  /* color: #40a9ff; */
  border: 1px solid #40a9ff;
  box-shadow: -1px 0 0 0 #40a9ff;
  border-radius: 4px;
}
.thirdstepRadioGroup .ant-radio-button-wrapper-checked {
  box-shadow: 0 0 7px 0 rgba(170, 170, 170, 0.5);
  border: solid 2px #3666c4;
  background-color: #ffffff;
}

#coordinated_messageType .ant-radio-button-wrapper {
  height: 65px;
}
.ant-radio-button-wrapper-checked:first-child {
  border: 1px solid #40a9ff;
  box-shadow: -1px 0 0 0 #40a9ff;
  border-radius: 4px;
}

.ant-radio-button-wrapper-checked:last-child {
  border: 1px solid #40a9ff;
  box-shadow: -1px 0 0 0 #40a9ff;
  border-radius: 4px;
}
.messagtype > .ant-form-item-control-wrapper > .has-error {
  text-align: center;
}
.interactNxt {
  float: right;
  text-transform: uppercase;
  color: #fff;
  background-color: #238661;
  border-color: #238661;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.interactNxt:hover {
  color: #238661;
  background-color: rgb(255, 255, 255);
  border-color: #238661;
}
.interactNxt:focus {
  color: #238661;
}
.ant-form label {
  font-size: 9px;
}
.socialIconLabel {
  /* position: absolute; */
  /* left: 10%; */
  /* text-align: center; */
  display: inline-flex;
  font-size: 12px;
  /* width: 20%; */
  /* top: 50%; */
}
