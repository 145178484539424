.interactLayout{
  background: transparent;
  padding: 10px; 
}
.highLight {
  background-color: #eeffdc;
}
.DeleteHighLight {
  background-color: red;
}
#Messagefixedbutton {
  position: fixed;
  width: 65px;
  height: 65px;
  bottom: 5px;
  right: 10px;
  background-color: #3666c4;
  border-radius: 100px;
  padding: 10px 5px;
  z-index: 99;
}
.steps-content {
  min-height: 200px;
  height: 30rem;
  text-align: left;
  padding-top: 2rem;
}
.foo {
  display: none;
  opacity: 0;
  animation: fade-out 1s 1;
}
.foo.fade-in {
  display: block;
  opacity: 1;
  animation: fade-in 0.5s 1;
}
.ant-tabs-nav .ant-tabs-tab {
  border: 2px solid #238661;
    height: 30px;
    padding: 2.4px 10px;
    width: 138px;
    text-align: center;
    border-radius: 40px;
    margin-bottom: 10px;
}
.ant-tabs-nav .ant-tabs-tab-active{
  border-color: #238661;
  background: #fff;
  color:  #1890ff !important;
}