.has-error .ant-form-explain, .has-error .ant-form-split{
    /* display: flex;
    text-align: center; */
    display: inline-flex;
}
#messageType .ant-radio-button-wrapper {
    height: 65px;
  }
  #messageType .ant-radio-button-wrapper {
    margin: 20px;
  }
  #messageType {
    width: 100%;
    text-align: center;
  }