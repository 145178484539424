.ant-page-header-heading-title{
    color: #3666c4
}
.utilityLayout{
    background: transparent;
    padding: 15px;
   
}
.ussedLayout{
    background: transparent;
    position: relative;
    top: -25px;
}
.utilityUploadButton{
    text-align: center;
    width:fit-content;
    display:flex;
    position: relative;
    left: 260px;
    top: 29px;
    border-radius: 6px;
    color: #ffffff;
    border-color:#238661;
  }
  .uploadButton{
    background-color: #238661;
    color: #ffffff;
  }
  .scroll{
    overflow-y: auto;
    height: 100%;
  }
  .BuzzBeeUploadButton{
    text-align: center;
    width:fit-content;
    display:flex;
    position: relative;
    left: -260px;
    top: 29px;
    border-radius: 6px;
    color:#238661;
    background-color: #238661;
    border-color:#238661;
  }
  .utilityDownloadButton{
    text-align: center;
    display: inherit;
    width:fit-content;
    top: -3px;
    position: relative;
    left: 640px;
    background-color: #238661;
    color: #ffffff;
    border-radius: 6px;
  }
  .buzzbeeDownloadButton{
    text-align: center;
    width:fit-content;
    display: inherit;
    position: relative;
    left: 640px;
    top: -3px;
    color: #ffffff;
    background-color: #238661;
    border-radius: 6px;
  }