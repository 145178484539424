.growerDetailInput{
background: #F3F4F6;
color: #000;
}
/* .ant-input:hover{
    color: #238661 !important;
    border-color: #238661 !important;
}
.ant-input:focus {
    font-weight: 500;
}
.ant-form label{
    color: #5C6575 !important;
    font-size: 12px !important;
}  */
.growerDetail> .ant-col .ant-form-item-label{
    text-transform: uppercase;
}
.buttonPosition{
    position: absolute;
    right: 24px;
    top: 150px;
}
.thButtonPosition{
    position: absolute;
    right: 24px;
    top: 200px;
    padding-bottom: 5px;
}
.myButtonPosition{
    position: absolute;
    right: 24px;
    top: 130px;
}
.idnButtonPosition{
    position: absolute;
    right: 24px;
    top: 260px;
}
.inButtonPosition{
    position: absolute;
    right: 28px;
    top: 105px;
}
.krButtonPosition{
    position: absolute;
    right: 24px;
    top: 100px;
    padding-bottom: 10px;
}
.buttonStyle{
    border-radius: 8px;
    color: #fff;
    background-color: #238661;
    width: 100px;
}
.colHeight{
    /* height: 450px;
    overflow-y: scroll; */
}
.detailFormModal> .ant-modal-body{
    padding-top: 5px !important;
  }
.growerDetails> .ant-card-head{
    padding: 0px;
}
.growerDetails> .ant-card-body{
        padding: 0px;
}
.growerDetails> .ant-card-head-title{
            padding: 12px 0;
}
.cropHistory{
    position: relative;
    top: -10px;
}
.cropHistory> .ant-card-head{
padding: 0px;
}
.cropHistory> .ant-card-body{
    padding: 0px;  
}
.cropHistory> .ant-card-head-title{
    padding: 12px 0;
}
.ant-input[disabled] {
    color: black;
}