.ant-row .ant-form-item{
    width: 93%;
}
.SearchButton{
    text-align: right;
    padding-right: 10px;
}
.RealoadButton{
    position: absolute;
    top: 50px;
    /* right: 285px; */
    right:140px;
}
.downloadButton{
    position: absolute;
    top: 50px;
    /* right: 410px; */
    right: 270px;
}
/* .SelectBox{
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 5px;
} */
.addButton{
    position: absolute;
    top: 59px;
    right: 10px;
    border: 0px solid transparent;
    box-shadow: none;
}
.searchButton{
    border-radius: 6px;
    color:#40a9ff;
    border-color:#40a9ff;
}
.searchSourceButton{
    border-radius: 6px;
    color:#40a9ff;
    border-color:#40a9ff;
    /* position: relative;
    float: left; */
    position: absolute;
    top: 50px;
    right: 140px;
}
.removeButton{
    border: 0px solid transparent;
    box-shadow: none;
    left: -8px;
    /* padding: 0 10px; */
}
.submitBtn {
    background-color: #5c6575;
    color: #ffffff;
  }
.ant-row{
    /* margin-left: 10px !important; */
    margin-top: 0px;
}
.ant-drawer-body{
    padding-bottom: 10px !important;
}
.ant-form-item {
    margin-bottom: 14px !important;
}
.ant-drawer-header {
    margin-bottom: 6px !important;
}
.ant-form-vertical .ant-form-item {
    padding-bottom: 0px;
}