.modalContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    position: absolute;
    left: 290px
}
.facebookContainer{
    width: 50%;
}
.facebookPreview{
    width: 40%;
    position: absolute;
    top: 75px;
    left: 490px;
    float: right;
}
.anticon-like{
margin-right: 6px;
}
.anticon-comment{
    margin-right: 6px;  
}
.anticon-share-alt{
    margin-right: 6px;
}
.ant-upload .ant-upload-drag{
    width: 40%;
}
.videoContentData{
    position: relative;
    left: 50px;
    top: 40px;
}
.videoContent{
    width: 51%;
    margin: 10px;
}
#thumbnailType{
    width: 100%;
    text-align: center;
}
#thumbnailType .ant-radio-button-wrapper {
    height: 75px;
  }
  #thumbnailType .ant-radio-button-wrapper {
    margin: 12px;
  }
.thumbnail{
    display: flex;
    position: relative;
   float: left;
}
.thumbnail img{
    width: 150px;
    height: 70px !important;
}
.postButton{
    display: flex;
    position: relative;
    left: 405px;
    top: -50px;
    float: right;
}
.progressPostion{
    position: relative;
    top: -17px;
}
.ant-card-type-inner .ant-card-head-title {
    padding: 12px 0;
    font-size: 14px;
    color:#40a9ff;
}
.postPreview> .ant-card-body{
    padding-top: 0px;
}
.location> .ant-card-body{
    padding-left: 23px;
    padding: 3px;
}
.ant-card-head-title{
    color: black !important;
    font-weight: 400 !important;
}
.postContent{
    height: 380px;
    overflow-y: scroll;
    padding-left: 20px;
    padding-top: 15px;
    background-color: #f2f2f2;
}
.fbPreview> .ant-form-item{
    width: 100%;
}
.uploadMedia{
    padding: 7px;
}
.uploadMedia> .ant-card-body{
padding: 2px;
}
.uploadMedia> .ant-card-head{
    padding-left: 30px;
    }
.writePost{
    padding: 7px;
}
.writePost> .ant-card-body{
    padding: 2px;
}
.ant-divider-horizontal{
        margin: 0px 0px;
        height: 4px;
    }
   .location> .ant-card-head{
       padding-left: 40px;
   }
   .writePost> .ant-card-head{
    padding-left: 30px;
}
::-webkit-scrollbar-track {
    cursor: pointer;
    border-radius: 5px;
    background: #C1C5C8;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    border-left: 2px solid white;
  }
  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    -webkit-box-shadow: inset 0 3px 2px 6px #808080;
  }

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    cursor: pointer;
  }
  /* .ant-card-meta-title{
color: #3658b1;
font-weight: 700;
  }
  .ant-card-meta-description{
    position: relative;
    top: -16px;
    left: -5px;
  } */