.sidebarChat {
    display: flex;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
    align-items: center;
    /* padding-bottom: 2px; */
}
.showCount{
    /* position: relative; */
    /* top: -15px; */
    /* right: 15px; */
    margin-bottom: 5px;
}
.ant-avatar-string{
    position: relative;
    left: 0%;
    transform-origin: 0 center;
}
a {
    text-decoration: none !important;
    color: black;
}
           
.sidebarChatName{
color: #000000;
}
.notification{
    color: navy;
}
.sidebarChat:hover {
    background-color: #ebebeb;
}

.sidebarChat__info {
    padding-left: 15px;
    width: 90%;
}
.notificationBar{
    margin-bottom: 5px;
    color: #000000;
    width: 20ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ant-badge{
    float: right;
    
}

.sidebarChat__info>h2 {
    font-size: 16px;
    margin-bottom: 5px;
    /* width: 100%; */
}
.notificationContainer{
    display: flex;
    justify-content: space-between;
}
.displayName{
    display: flex;
    justify-content:space-between ;
}
.dateTime{
    color: #128C7E;
    /* margin-left: 5px; */
    margin-bottom: 5px;
    /* position: relative; */
    /* right: -72px; */
}
.sidebarChat__info>p {
    word-break: break-all;
}

.ReactModal__Content>h1 {
    color: black;
    margin: 5px;
}

.ReactModal__Content>form>input {
    margin: 10px;
    font-size: 20px;
    padding: 20px 30px;
    border-radius: 39px;
    border:none;
}

.ReactModal__Content>form>button {
    height: 50px;
    border: none;
    color: white;
    margin-top: 15px;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: #0a8d48;
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
}

.ReactModal__Content>form {
    margin-left: 5px;
}