.groupLayout {
  background: transparent;
  padding: 10px;
}
#fixedbutton {
  position: relative;
  float: right;
  top: -45px;
  width: 65px;
  height: 65px;
  bottom: 10px;
  right: 10px;
  background-color: #3666c4;
  border-radius: 100px;
  padding: 10px 5px;
  z-index: 99;
}
.NewGroupCard {
  padding: 0px;
}
.groupDrawer .ant-drawer-body{
  height: 94%;
  overflow: auto;
}
.NewGroupCard .ant-card-body {
  padding: 0px;
  margin-top: 3%;
}
.creatGroupBtn {
  background-color: #5c6575;
  color: #ffffff;
  width: 100%;
}
.groupType {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.operatorSize{
  font-size: 18px;
}
.creatGroupBtn:hover,
.creatGroupBtn:active,
.creatGroupBtn:focus,
.ant-input:hover,
.ant-input:focus {
  color: #5c6575;
  background-color: transparent;
  border-color: #5c6575;
  box-shadow: none;
}
.filterTypeRegion {
  border-color: #dbdbdb;
  box-shadow: none;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
}
.AcresItems .has-error .ant-form-explain, .has-error .ant-form-split{
display: block;
width: 160px;
}
.filterTypeRegion .form .ant-select {
  width: 40%;
  border-color: #5c6575;
  box-shadow: none;
  border: 1px solid;
  border-radius: 20px;
}
.filterTypeCrop {
  border-color: #dbdbdb;
  box-shadow: none;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  margin: 0 10px;
}
.growersOperator{
  width: 100px;
  /* border: 1px solid black; */
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 15px;
}
.growerInput{
  width: 123px;
  position: absolute;
  top: 0px;
}
.AcresItems{
  margin-left: 0px;
  height: 50px;
}
.acresOperator{
  display: inline-block;
  width: calc(25% - 5px);
  margin-right: 8px;
}
.acresValue{
  display: inline-block;
  width: calc(67% - 5px);
}
.filterTypeCrop .form .ant-select {
  width: 50%;
  border-color: #5c6575;
  box-shadow: none;
}

/*********Overrie css classes**/
.ant-drawer-header {
  padding: 10px 40px;
}
.groupcreateForm > .ant-form-item {
  margin-bottom: 8px !important;
}
.ant-form-explain,
.ant-form-extra {
  font-size: 13px;
}
.ant-form-item-label {
  line-height: 25px;
}
.ant-form-item-control {
  line-height: 40px;
}
.ant-form-item {
  margin-bottom: 8px;
}