.webLayout {
  background: transparent;
}

.webstoreTitleDiv {
  height: 55px;
  padding: 5px 10px;
  display: inline-flex;
  background: transparent;
  border-bottom: 1px solid #1d7152a3;
}
.webStoreTitle {
  margin-bottom: 0em;
  font-size: 16px;
  color: #3666c4;
  font-weight: bold;
}

.webstoreMainContent {
  padding: 0px 30px;
  overflow-y: scroll;
  min-height: 440px;
  height: 480px;
}

.addmoreGrowers {
  text-transform: uppercase;
  font-size: 12px;
}
.viewDetailsEngage {
  font-size: 14px;
}
.webstoreLeftLink {
  line-height: 5px;
}
.webstoreRightLink {
  line-height: 5px;
}
.singleCardMeta {
  padding: 10px 0px;
}
/********OverRide classess*****/
.webstoreMainContent.ant-card {
  background: transparent;
}

.webstoreMainContent .ant-card-bordered {
  border: 0px;
  cursor: pointer;
}
.webstoreMainContent .ant-card.ant-card-body.ant-card-grid {
  padding: 0;
}
.ant-card {
  background-color: transparent;
}
.webstoreLeftLink h4.ant-typography,
.ant-typography h4,
.webstoreRightLink h4.ant-typography,
.ant-typography h4 {
  font-size: 16px;
}
.ant-card-head {
  border-bottom: 0;
}

.webstoreMainCard
  > .ant-card-head
  > .ant-card-head-wrapper
  > .ant-card-head-title {
  padding: 10px 0;
}

.webstoreMainCard > .ant-card-body > .ant-card-grid {
  padding: 10px;
}
#normal_login_SocialPlatform > .ant-radio-button-wrapper {
  padding: 6px 6px;
  height: 50px;
  width: 65px;
  margin: 2px 11px;
}

.webstoreMainCard .ant-card-head {
  color: #3666c4;
  font-weight: bold;
}
.WebstoreSingleCard > .ant-card-body {
  padding: 10px;
}
/******* Media Queries **/
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 400px) and (max-width: 799px) {
}
/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 800px) and (max-width: 999px) {
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1000px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1259px) {
}
@media (min-width: 1260px) and (max-width: 1399px) {
}
@media (min-width: 1400px) and (max-width: 1450px) {
}
/* Media Query for Large screens */
@media (min-width: 1451px) and (max-width: 1550px) {
}
/* Media Query for Large screens MYSELF Desk Screen(hp bang & olufsen) */
@media (min-width: 1900px) and (max-width: 2000px) {
  .webstoreMainContent {
    height: 840px;
  }
}

/* Media Query for Large screens */
@media (min-width: 2000px) and (max-width: 9000px) {
}
