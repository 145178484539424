.connected {
    flex: 0.72 1;    
    display: flex;
    flex-direction: column;
    height: calc(100% - 0px);
}
.chat-room-name {
    margin: 0px;
}
.chat-room-last-seen {
    margin: 0;
}
.chat_header {
    display: flex;
    padding: 11px;
    align-items: center;
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
}
.chat_headerInfo {
    flex: 1;
    padding-left: 20px;
}

.chat_headerInfo > h3 {
    margin-bottom: 3px;
    font-weight: 500;
}

.chat_headerInfo > p {
    color:gray;
}

.chat_headerRight {
    display: flex;
    justify-content: space-between;
    min-width: 100px;
}
.wifi-connect{
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0;
    left: 55%;
}
.connect-text{
    position: absolute;
    top: 78%;
    left: 46%;
    /* display: flex;
    justify-content: center; */
}
.connect-text > h1 {
    font-size: 32px;
    font-weight: 300;
}
.connected_body {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    background-color: #f8f9fa;
    border-left: 1px solid lightgray;
    /* background-color: peachpuff; */
    /* background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png'); */
    background-repeat: repeat;
    /* background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTawkeTJaQxn-WLgCr52UJwlJ5W8czbAWr7w&usqp=CAU'); */
    background-position: center;
    background-position: 50%;
    padding: 30px;
    overflow:auto; 
}
.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper {
    display: block;
}
/* .ant-form-item {
    margin-bottom: 0px;
} */
/* .chat_message {
    position: relative;
    font-size: 16px;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    background-color: #2d3e50;
    width: fit-content;
} */
/* 
.chat_messageSent {
    position: relative;
    font-size: 16px;
    float: right;
    margin-left: auto;
    background-color: #dcf8c6;
     background-color: #238661;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    width: fit-content;
} */
.chat_name {
    position: absolute;
    top:-15px;
    font-weight: 800;
    font-size: xx-small;
    color: #000000;
}


.chat_messageText {
    word-wrap: break-word;
    color:#000000;
    /* color: #ffffff; */
}
.chat_receiver{
    margin-left: auto;
    background-color: #dcf8c6;
}

.chat_footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    border: 1px solid lightgray;
}

.chat_footer > form{
    flex: 1;
    display: flex;
}

.chat_footer > form > input{
    flex: 1;
    border-radius: 30px;
    padding: 10px;
    border: none;
    margin: 0 10px 0px 10px;
}

.chat_footer > form > button{
    /* display: none; */
}
.chat_footer> form > .ant-form-item{
    margin-bottom: 1px !important;
}
.emoji-mart-preview{
    display: none;
}