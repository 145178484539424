.servicePreview {
  padding: 7px;
  /* width: 390px; */
  height: 180px;
  margin: 0 auto;
}
.WebstoreNewMessgeCard {
  text-align: left;
}
.WebstoreNewMessgeCard .ant-card-body {
  padding: 0px 10px;
}

/******overide css classes*/

.webstoreDrawer
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-body {
  padding: 0;
}
.webstoreDrawer .ant-drawer-header {
  text-align: center;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
  padding: 6px;
  text-align: left;
}
