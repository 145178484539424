.webstore-form .ant-form-item {
  margin-bottom: 10px;
}
.webstoreRadioGroup {
  text-align: center;
  width: 100%;
}
.serviceIcon svg {
  vertical-align: -webkit-baseline-middle;
}
.webstoreRadioGroup .ant-radio-button-wrapper {
  height: 45px;
  /* box-shadow: 0 0 7px 0 rgba(170, 170, 170, 0.5);
  background-color: #ffffff; */
}
.webstoreRadioGroup .ant-radio-button-wrapper-checked {
  box-shadow: 0 0 7px 0 rgba(170, 170, 170, 0.5);
  /* border: solid 2px #3666c4; */
  background-color: #ffffff;
}
.costMsg {
  color: red;
   display: flex; 
  position: relative;
  top: 2px; 
  font-size: 13px;
  line-height: 16px !important;
}
.costErrorMsgText{
  color: red;
  display: block;
  line-height: 20px;
  position: relative;
  top:-10px;
}
.loadingCost{
  color: red;
}
.sendNow {
  float: right;
  text-transform: uppercase;
  margin: 0 0 0 4%;
  color: #fff;
  background-color: #238661;
  border-color: #238661;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.schedule {
  float: right;
  margin: 0 0 0 4%;
  color: #fff;
  background-color: #238661;
  border-color: #238661;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.webstore-form .has-error .ant-form-explain{
  position: absolute !important;
}

.sendNow:hover {
  color: #238661;
  background-color: rgb(255, 255, 255);
  border-color: #238661;
}
.webstoreSocialIconLabel {
  display: inline-flex;
  font-size: 12px;
}
.ant-form label {
  font-size: 9px;
  display: inline-block;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #3666c4;
}

