.highLight {
  background-color: #eeffdc;
}
/********ovreride css classess****/

.ant-table-thead > tr > th {
  /* background: #edeff2; */
  /* color: rgba(0, 0, 0, 0.85); */
  font-weight: bold;
}

.ant-table-tbody > tr > td {
  color: rgba(0, 0, 0, 0.85);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 5px;
}
/* .ant-table-tbody > tr :nth-child(4) {
 color:#6dd400;
}
.ant-table-tbody > tr :nth-child(5) {
  color:#e02020;
 } */
.ant-table-pagination.ant-pagination {
  margin: 10px 0;
}

.ant-table-body .ant-table-fixed .ant-table-tbody tr {
  /* background-color: tomato; */
}
.ant-table-header{
  margin-right: 15px;
  }
