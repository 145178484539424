.fingrowerLayout {
  background: transparent;
  padding: 10px;
}
.listCompDiv {
  padding: 0;
  background: transparent;
}

.color {
  color: #6dd400;
}
.highLight {
  background-color: #eeffdc;
}
.pageTitleDiv {
  margin: 5px 5px;
}
.ant-descriptions-bordered .ant-descriptions-item-label{
  font-weight: 600;
}
/******* Media Queries **/
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 400px) and (max-width: 799px) {
}
/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 800px) and (max-width: 999px) {
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1000px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1259px) {
}
@media (min-width: 1260px) and (max-width: 1399px) {
}
@media (min-width: 1400px) and (max-width: 1450px) {
  .pageTitleDiv {
    margin: 4% 2% 0;
    z-index: 1;
    width: 82.5%;
  }
}
/* Media Query for Large screens */
@media (min-width: 1451px) and (max-width: 1550px) {
}