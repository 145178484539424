.ant-table-header {
    overflow-y: auto !important;
    margin-right: 0px !important;
  }
 .viewButton{
  color: #238661;
 }
 .deleteButton{
  color: #ff4d4f;
 }
 .editButton{
  color: #1890ff;
 }
 .ant-popover-inner-content{
  padding: 12px 16px !important;
 }
 .showMoreButton{
   color: #000000;
 }
 /* .GroupCard{
  width: 320px;
  height: 215px;
 }
 .GroupCard{
  height: auto;
  max-height: 250px;
  width: auto;
  max-width: 250px;
 } */
 .GroupCard{
  /* width: auto; */
  /* height: 150px; */
  /* margin-bottom: 10px; */
  border-radius: 8px;
  box-shadow: 10px 10px 5px #aaaaaa;

 }
 .GroupCard{
  /* height: auto; */
  /* max-height: 210px; */
  /* width: auto; */
  /* max-width: 250px; */
  /* margin-bottom: 10px; */
 }
 .GroupCard> .ant-card-body{
  min-height: 92px;
max-height: 92px;
  /* overflow: auto; */
  text-overflow: ellipsis;
}
 .GroupCard> .ant-card-head{
     border-bottom: 1px solid gainsboro;
}
.GroupCard .ant-card-head-title{
  color: black !important;
  font-weight: 600 !important;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  line-height: 16px;
}
#read-more {
  visibility: hidden;
  /* position: relative;
  top: -16px;
  left: 160px; */
  /* float: right; */
  /* text-decoration: underline; */
}

.ant-card {
  /* background-color: #ffffff !important; */
  /* background-color: transparent; */
}
.GroupCard .ant-card-meta-description{
  color: #000000;
  padding: 2px;
  text-align: left;
}
.GroupCard .ant-card-actions{
  background-color: #ffffff;
  border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.modifiedDetails{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  font-size: 12px;
  top: -10px;
}
.modifiedDetails> P {
  margin-bottom: 0;
}