.youtubeTable .ant-pagination-item {
    display: none; 
    text-align: left;
  }
  .videoContainer{
      display: flex;
  }
  .videoTitle{
    margin-left: 10px;
    width: 200px;
    text-align: left;
  }
  .videoDescription{
    font-weight: 500;
  }