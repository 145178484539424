.socialLayout {
  background: white;
}
.headingText{
  font-weight: bold;
  font-size: 16px;
  color:#238661;
}
.ant-layout-header{
  background: transparent;
}
.ant-card-head-title{
  font-weight: bold;
  font-size: 16px;
  color:#238661;
}
.titleSection{
  position: relative;
  top: 20px;
  left: 25px
}
.ant-layout{
  color: #ffffff;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  border: 2px solid #238661;
    height: 30px;
    padding: 2.4px 10px;
    width: 138px;
    text-align: center;
    border-radius: 40px;
    margin-bottom: 10px;
}
.ant-tabs-nav .ant-tabs-tab-active{
  border-color: #238661;
  background: #fff;
  color:  #c4302b !important;
}
.ant-card-head .ant-tabs-bar {
padding-top: 15px;
}
.ant-tabs-ink-bar{
  display: none !important;
}
.cardSubTitle > .ant-card-head{
  border-bottom: 1px solid gray;
}
.ant-card-body{
  border:0px;
}
.ant-tabs-bar{
  border-bottom: 0px !important;
}
.facebookPostImages{
  max-width: 100%; 
display:block; 
height: auto;
}
 .ant-card {
  background-color: #ffffff;
}
.likeIcon{
width: 20px;
height: 20px;
background-color: #1890ff;
border-radius: 10px;
padding: 2px;
color: #fff;
}

.commentIcon{
  width: 20px;
  height: 20px;
  background-color: #238661;
  border-radius: 10px;
  padding: 2px;
  color: #fff;
  }
  .shareIcon{
    width: 20px;
    height: 20px;
    background-color: #3666c4;
    border-radius: 10px;
    padding: 2px;
    color: #fff;
    }
    .ant-card-type-inner .ant-card-head {
      padding: 0 24px;
      background: #fff;
      border-bottom: 1px solid #e8e8e8;
  }
  .ant-card-actions {
    margin: 0;
    padding: 0; 
    list-style: none;
     background: #fff; 
    border-top: 1px solid #e8e8e8;
    zoom: 1;
}