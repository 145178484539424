.reportLayout {
  background: transparent;
  height: 100%;
}
.reportMainContent {
  padding: 0px 20px;
  overflow-y: scroll;
  min-height: 440px;
  height: 480px;
}

.reportCard > .ant-card-body {
  padding: 0;
}
.counterCard {
  border: 1px solid #238661;
}
.counterCard .ant-card-body {
  padding: 10px;
}
