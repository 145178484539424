.ComSonmainContent {
  background: #fafbfc;
  margin: 4% 1% 0;
  z-index: 1;
  width: 82.5%;
  background-size: contain;
}
.comingson {
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: 98%;
  margin: 1% 1%;
  height: 500px;
  border-radius: 5px;
  text-align: center;
}
.inprogressh1 {
  font-size: 60px;
  margin: 0 auto;
}
