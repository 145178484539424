/** Custom css classes*/
.logo {
  width: 140px;
  height: 31px;
  margin: 0px 20px 0px 0;
  float: left;
}
.mainHeader {
  width: 100%;
  color: white;
  /* background: #15563e; */
  padding: 0 15px;
  z-index: 99;
  height: 40px;
  line-height: 40px;
}
.mainBread {
  margin: 0px 0;
  float: left;
  width: 50%;
}
.rightMenu {
  float: right;
  text-align: right;
  background: transparent;
  color: #ffffff;
  display: inline-flex;
}
.rightMenu span {
  padding: 0px 0 0 12px;
  cursor: pointer;
}
.rightMenu ul li:hover {
  color: #5c6575;
}
.righmenuLink {
  cursor: pointer;
}

.LangDropdown {
  left: 1150px;
  top: 64px;
  width: 9%;
}
.logoutspan {
  color: #000000a6;
  cursor: pointer;
  position: relative;
  top: -40px;
  left: 24px;
}
.logoutspan:hover {
  color: #5c6575;
}

.languageDrop {
  width: 100%;
  border: 1px solid #8080804d;
  border-radius: 4px;
}
.ant-select-arrow {
  color: #d9d9d9;
  display: block;
}

/**** override css code *******/

.ant-menu-horizontal {
  border-bottom: 0px;
}
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 0px;
}

.ant-menu-item .ant-menu-item-selected:focus {
  background: transparent;
  outline: none;
  border: 0;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: transparent;
}
.ant-menu-submenu > .ant-menu {
  background-color: transparent;
}

.ant-popover {
  /* left: 84% ; */
  right: 0;
  width: 18%;
}
.ant-menu-item > a > span {
  color: #5c6575;
}
.ant-menu-item:hover {
  color: #5c6575;
}
.ant-menu-item:hover i > svg > g {
  fill: #5c6575;
}

.ant-menu-item:hover i > svg > path {
  fill: #5c6575;
}
.ant-menu-item-selected {
  color: #5c6575;
}
.ant-menu-item-active {
  color: #5c6575;
}

/* .ant-select-selection--single {
  display: contents;
} */
.ant-popover-inner-content ul .ant-menu-item > a > span {
  color: #5c6575;
}
.ant-popover-inner-content ul .ant-menu-item > .anticon {
  color: #5c6575;
  font-size: 14px;
}
.ant-popover-inner-content ul .ant-menu-item > a > .anticon {
  color: #5c6575;
  font-size: 14px;
}
.ant-popover-inner-content ul .ant-menu-item > a > span {
  color: #5c6575;
  font-size: 14px;
}
.ant-popover-inner-content ul .ant-menu-item > a:hover {
  color: #5c6575;
}
.ant-popover-inner-content ul .ant-menu-item-selected > a > span {
  color: #5c6575;
}
.ant-menu-item-selected > a > span {
  color: #5c6575;
}
.ant-menu-item-selected > a > span:hover {
  color: #5c6575;
}
.popupmenuList .ant-menu-item-selected > a {
  color: #5c6575;
}
.popupmenuList > .ant-menu-item {
  height: 45px;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #5c6575;
}

.ant-popover-inner-content
  ul
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  color: #5c6575;
}
.rightMenu
  > .ant-select
  > .ant-select-selection--single
  > .ant-select-selection__rendered
  > .ant-select-selection-selected-value {
  height: 24px;
  text-align: left;
  width: 66px;
  background-color: white;
  border-radius: 20px;
  color: black;
  font-size: 12px;
}
/* .ant-select-arrow {
  color: white;
  display: none;
} */

.rightMenu
  > .ant-select
  > .ant-select-selection--single
  > .ant-select-selection__rendered {
  line-height: 25px;
  margin-right: 0px;
}
.rightMenu .ant-select {
  padding: 8px 5px;
}
.rightMenu
  > .ant-select
  > .ant-select-selection--single{
    display: contents;
  }
.regionCode{
  width: 66px;
  height: 24px;
  text-align: left;
  background-color: #fff;
  border-radius: 20px;
  font-size: 12px;
  position: relative;
  top: 8px;
  left: -10px;
}
.logoutLink {
  display: inline-block;
}

.popupmenuList > .ant-menu-item {
  height: 45px;
}
.ant-popover-inner-content {
  padding: 5px 3px;
}

/******* Media Queries **/
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 400px) and (max-width: 799px) {
}
/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 800px) and (max-width: 999px) {
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1000px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1259px) {
}
@media (min-width: 1260px) and (max-width: 1399px) {
}
@media (min-width: 1400px) and (max-width: 1450px) {
}
/* Media Query for Large screens */
@media (min-width: 1451px) and (max-width: 1550px) {
}
/* Media Query for Large screens */
@media (min-width: 1900px) and (max-width: 2000px) {
}

/* Media Query for Large screens */
@media (min-width: 2000px) and (max-width: 9000px) {
}
